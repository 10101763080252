import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Label } from 'semantic-ui-react';

import { fetchCollections, fetchCollectionInfo } from '../services/collection';

const AuthenticatedCollections = ({ config }) => {
  const [collections, setCollections] = useState([]);
  const [collectionCounts, setCollectionCounts] = useState({});

  useEffect(() => {
    const loadCollections = async () => {
      try {
        const collections = await fetchCollections(config);
        setCollections(collections);

        // Fetch item counts for each collection
        const counts = await Promise.all(
          collections.map(async (collection) => {
            const info = await fetchCollectionInfo(collection);
            return { [collection]: info };
          })
        );

        // Convert counts array to an object for easy access
        const countsObj = counts.reduce((acc, curr) => ({ ...acc, ...curr }), {});
        setCollectionCounts(countsObj);
      } catch (error) {
        console.error('Failed to fetch collections:', error);
      }
    };

    loadCollections();
  }, []); // Empty dependency array ensures this runs only once

  return (
    <>
      {collections.map((collection_name) => (
        <Menu.Item
          key={collection_name}
          as={Link}
          to={`/collections/${collection_name}`}
          name={collection_name}
        >
          {collection_name}

          {collectionCounts && collectionCounts[collection_name] ? (
            <Label color="teal" style={{ marginLeft: '10px' }}>
              {collectionCounts[collection_name].count}
            </Label>
          ) : (
            <Label color="teal" style={{ marginLeft: '10px' }}>
              ?
            </Label>
          )}
        </Menu.Item>
      ))}

      <Menu.Item
        as={Link}
        to="/collections/create"
        name="New..."
      />
    </>
  );
};

export default AuthenticatedCollections;
