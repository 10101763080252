export const DEFAULT_MODELS = {
    embed: {
      "all-minilm-l6-v2": {
        api_name: "sentence-transformers/all-minilm-l6-v2",
        description: "Our model is intended to be used as a sentence and short paragraph encoder. Given an input text, it outputs a vector which captures the semantic information. The sentence vector may be used for information retrieval, clustering or sentence similarity tasks. By default, input text longer than 256 word pieces is truncated.",
        provider: {
          name: "sentence-transformers",
          links: {
            org: "https://huggingface.co/sentence-transformers",
            model: "https://huggingface.co/sentence-transformers/all-MiniLM-L6-v2",
          },
        },
        inputs: ["text"],
        outputs: ["vector"],
        parameters: {
          length: 384
        }
      },
      "all-minilm-l12-v2": {
        api_name: "sentence-transformers/all-minilm-l6-v2",
        description: "Maps sentences & paragraphs to a 384 dimensional dense vector space and can be used for tasks like clustering or semantic search.",
        provider: {
          name: "sentence-transformers",
          links: {
            org: "https://huggingface.co/sentence-transformers",
            model: "https://huggingface.co/sentence-transformers/all-MiniLM-L12-v2",
          }
        },
        inputs: ["text"],
        outputs: ["vector"],
        parameters: {
          length: 384
        }
      },
      "labse": {
        api_name: "sentence-transformers/labse",
        description: "The language-agnostic BERT sentence embedding encodes text into high dimensional vectors. The model is trained and optimized to produce similar representations exclusively for bilingual sentence pairs that are translations of each other. So it can be used for mining for translations of a sentence in a larger corpus.",
        provider: {
          name: "sentence-transformers",
          links: {
            org: "https://huggingface.co/sentence-transformers",
            model: "https://huggingface.co/sentence-transformers/LaBSE",
            original_model: "https://www.kaggle.com/models/google/labse/tensorFlow2/labse/1",
          }
        },
        inputs: ["text"],
        outputs: ["vector"],
        parameters: {
          length: 768
        }
      },
      "paraphrase-multilingual-mpnet-base-v2": {
        api_name: "sentence-transformers/paraphrase-multilingual-mpnet-base-v2",
        description: "Maps sentences & paragraphs to a 768 dimensional dense vector space and can be used for tasks like clustering or semantic search.",
        provider: {
          name: "sentence-transformers",
          links: {
            org: "https://huggingface.co/sentence-transformers",
            model: "https://huggingface.co/sentence-transformers/paraphrase-multilingual-mpnet-base-v2"
          }
        },
        inputs: ["text"],
        outputs: ["vector"],
        parameters: {
          length: 768
        }
      },
      "paraphrase-multilingual-minilm-l12-v2": {
        api_name: "sentence-transformers/paraphrase-multilingual-mpnet-base-v2",
        description: "Maps sentences & paragraphs to a 384 dimensional dense vector space and can be used for tasks like clustering or semantic search.",
        provider: {
          name: "sentence-transformers",
          links: {
            org: "https://huggingface.co/sentence-transformers",
            model: "https://huggingface.co/sentence-transformers/paraphrase-multilingual-MiniLM-L12-v2"
          }
        },
        inputs: ["text"],
        outputs: ["vector"],
        parameters: {
          length: 384
        }
      },
      "e5-small-v2": {
        api_name: "intfloat/e5-small-v2",
        description: "E5 can be readily used as a general-purpose embedding model for any tasks requiring a single-vector representation of texts such as retrieval, clustering, and classification, achieving strong performance in both zero-shot and fine-tuned settings",
        provider: {
          name: "intfloat",
          links: {
            org: "https://huggingface.co/intfloat",
            model: "https://huggingface.co/intfloat/e5-small-v2"
          }
        },
        inputs: ["text"],
        outputs: ["vector"],
        parameters: {
          length: 384
        }
      },
      "e5-small-unsupervised": {
        api_name: "intfloat/e5-small-unsupervised",
        description: "This model is similar to e5-small but without supervised fine-tuning.",
        provider: {
          name: "intfloat",
          links: {
            org: "https://huggingface.co/intfloat",
            model: "https://huggingface.co/intfloat/e5-small-unsupervised"
          }
        },
        inputs: ["text"],
        outputs: ["vector"],
        parameters: {
          length: 384
        }
      },
      "multilingual-e5-large-instruct": {
        api_name: "intfloat/multilingual-e5-large-instruct",
        description: "Each query must come with a one-sentence instruction that describes the task.",
        provider: {
          name: "intfloat",
          links: {
            org: "https://huggingface.co/intfloat",
            model: "https://huggingface.co/intfloat/multilingual-e5-large-instruct"
          }
        },
        inputs: ["text"],
        outputs: ["vector"],
        parameters: {
          length: 1024
        }
      },
      "sentence-t5-large": {
        api_name: "sentence-transformers/sentence-t5-large",
        description: "Maps sentences & paragraphs to a 768 dimensional dense vector space. The model works well for sentence similarity tasks, but doesn't perform that well for semantic search tasks. The input is variable-length English text and the output is a 768-dimensional vector. Note that there's no hard length limit for T5 (i.e., no 512 tokens limit as for BERT), but that it's been trained to produce good embeddings for approximately sentence length text.",
        provider: {
          name: "intfloat",
          links: {
            org: "https://huggingface.co/sentence-transformers",
            model: "https://huggingface.co/sentence-transformers/sentence-t5-large",
            original_model: "https://www.kaggle.com/models/google/sentence-t5/tensorFlow2/st5-large/1"
          }
        },
        inputs: ["text"],
        outputs: ["vector"],
        parameters: {
          length: 768
        }
      },
      "snowflake-arctic-embed-xs": {
        api_name: "snowflake/snowflake-arctic-embed-xs",
        description: "snowflake-arctic-embed is a suite of text embedding models that focuses on creating high-quality retrieval models optimized for performance. capable of producing highly compressible embedding vectors that preserve quality even when squished as small as 128 bytes per vector.",
        provider: {
          name: "Snowflake",
          links: {
            org: "https://huggingface.co/Snowflake",
            model: "https://huggingface.co/Snowflake/snowflake-arctic-embed-xs",
            paper: "https://arxiv.org/abs/2405.05374"
          }
        },
        inputs: ["text"],
        outputs: ["vector"],
        parameters: {
          length: 384
        }
      },
      "snowflake-arctic-embed-m": {
        api_name: "snowflake/snowflake-arctic-embed-m",
        description: "snowflake-arctic-embed is a suite of text embedding models that focuses on creating high-quality retrieval models optimized for performance. capable of producing highly compressible embedding vectors that preserve quality even when squished as small as 128 bytes per vector.",
        provider: {
          name: "Snowflake",
          links: {
            org: "https://huggingface.co/Snowflake",
            model: "https://huggingface.co/Snowflake/snowflake-arctic-embed-m",
            blog: "https://www.snowflake.com/engineering-blog/arctic-embed-m-v1-5-enterprise-retrieval",
            paper: "https://arxiv.org/abs/2405.05374"
          }
        },
        inputs: ["text"],
        outputs: ["vector"],
        parameters: {
          length: 768
        }
      },
      "gtr-t5-large": {
        api_name: "sentence-transformers/gtr-t5-large",
        description: "",
        provider: {
          name: "sentence-transformers",
          links: {
            org: "https://huggingface.co/sentence-transformers",
            model: "https://huggingface.co/sentence-transformers/gtr-t5-large",
            original_model: "https://www.kaggle.com/models/google/gtr/tensorFlow2/gtr-large/1",
            paper: "https://arxiv.org/abs/2112.07899"
          }
        },
        inputs: ["text"],
        outputs: ["vector"],
        parameters: {
          length: 768
        }
      }
    },
    instruct: {
      "qwen2-0.5b-instruct": {
        api_name: "qwen/qwen2-0.5b-instruct",
        description: "Qwen2 is the new series of Qwen large language models. For Qwen2, we release a number of base language models and instruction-tuned language models ranging from 0.5 to 72 billion parameters, including a Mixture-of-Experts model. This repo contains the instruction-tuned 0.5B Qwen2 model.",
        provider: {
          name: "qwen",
          description: "large language models created by Alibaba Cloud",
          links: {
            org: "https://huggingface.co/Qwen",
            model: "https://huggingface.co/Qwen/Qwen2-0.5B-Instruct"
          }
        },
        inputs: ["chat"],
        outputs: ["chat"]
      },
      "qwen2-1.5b-instruct": {
        api_name: "qwen/qwen2-1.5b-instruct",
        description: "Qwen2 is the new series of Qwen large language models. For Qwen2, we release a number of base language models and instruction-tuned language models ranging from 0.5 to 72 billion parameters, including a Mixture-of-Experts model. This repo contains the instruction-tuned 1.5B Qwen2 model.",
        provider: {
          name: "qwen",
          description: "large language models created by Alibaba Cloud",
          links: {
            org: "https://huggingface.co/Qwen",
            model: "https://huggingface.co/Qwen/Qwen2-1.5B-Instruct"
          }
        },
        inputs: ["chat"],
        outputs: ["chat"]
      },
      "phi-3-mini-128k-instruct": {
        api_name: "microsoft/phi-3-mini-128k-instruct",
        description: "The Phi-3-Mini-128K-Instruct is a 3.8 billion-parameter, lightweight, state-of-the-art open model trained using the Phi-3 datasets. This dataset includes both synthetic data and filtered publicly available website data, with an emphasis on high-quality and reasoning-dense properties. The model belongs to the Phi-3 family with the Mini version in two variants 4K and 128K which is the context length (in tokens) that it can support.",
        provider: {
          name: "Phi-3.5",
          description: "large language models created by Microsoft",
          links: {
            org: "https://huggingface.co/microsoft",
            model: "https://huggingface.co/microsoft/Phi-3-mini-128k-instruct"
          }
        },
        inputs: ["chat"],
        outputs: ["chat"]
      },
      "llama-3.2-1b-instruct": {
        api_name: "meta-llama/llama-3.2-1b-instruct",
        description: "The Llama 3.2 collection of multilingual large language models (LLMs) is a collection of pretrained and instruction-tuned generative models in 1B and 3B sizes (text in/text out). The Llama 3.2 instruction-tuned text only models are optimized for multilingual dialogue use cases, including agentic retrieval and summarization tasks. They outperform many of the available open source and closed chat models on common industry benchmarks.",
        provider: {
          name: "Llama 3.2",
          description: "large language models created by Meta",
          links: {
            org: "https://huggingface.co/meta-llama",
            model: "https://huggingface.co/meta-llama/Llama-3.2-1B-Instruct"
          }
        },
        inputs: ["chat"],
        outputs: ["chat"]
      }
    },
    vision: {
      "smolvlm": {
        api_name: "huggingfacetb/smolvlm-instruct",
        description: "SmolVLM is a compact open multimodal model that accepts arbitrary sequences of image and text inputs to produce text outputs.",
        provider: {
          name: "huggingface",
          links: {
            org: "https://huggingface.co/HuggingFaceTB",
            model: "https://huggingface.co/HuggingFaceTB/SmolVLM-Instruct",
            blog: "https://huggingface.co/blog/smolvlm"
          }
        },
        inputs: ["text", "image"],
        outputs: ["text"]
      }
    },
    depth: {
      "dpt-dinov2-small-kitti": {
        api_name: "facebook/dpt-dinov2-small-kitti",
        description: "DPT (Dense Prediction Transformer) model with DINOv2 backbone as proposed in DINOv2: Learning Robust Visual Features without Supervision by Oquab et al.",
        provider: {
          name: "meta",
          description: "DINOv2",
          links: {
            org: "https://huggingface.co/facebook",
            model: "https://huggingface.co/facebook/dpt-dinov2-small-kitti",
            paper: "https://arxiv.org/abs/2304.07193"
          }
        },
        inputs: ["image"],
        outputs: ["image"]
      },
      "dpt-large": {
        api_name: "intel/dpt-large",
        description: "Dense Prediction Transformer (DPT) model trained on 1.4 million images for monocular depth estimation.",
        provider: {
          name: "Intel",
          links: {
            org: "https://huggingface.co/Intel",
            model: "https://huggingface.co/Intel/dpt-large",
            paper: "https://arxiv.org/abs/2103.13413"
          }
        },
        inputs: ["image"],
        outputs: ["image"]
      },
      "dpt-hybrid-midas": {
        api_name: "intel/dpt-hybrid-midas",
        description: "DPT-Hybrid diverges from DPT by using ViT-hybrid as a backbone and taking some activations from the backbone..",
        provider: {
          name: "Intel",
          links: {
            org: "https://huggingface.co/Intel",
            model: "https://huggingface.co/Intel/dpt-hybrid-midas",
            paper: "https://arxiv.org/abs/2103.13413"
          }
        },
        inputs: ["image"],
        outputs: ["image"]
      },
      "glpn-nyu": {
        api_name: "vinvino02/glpn-nyu",
        description: "Global-Local Path Networks (GLPN) model trained on NYUv2 for monocular depth estimation.",
        provider: {
          name: "KAIST South Korea",
          links: {
            org: "https://huggingface.co/vinvino02",
            model: "https://huggingface.co/vinvino02/glpn-nyu",
            paper: "https://arxiv.org/abs/2201.07436"
          }
        },
        inputs: ["image"],
        outputs: ["image"]
      }
    }
};

export default DEFAULT_MODELS;
