import React, { useEffect, useState } from 'react';
import { Button, Table, Header, Icon, Message, Loader } from 'semantic-ui-react';
import { createUserApiKey, listUserApiKeys, deleteUserApiKey } from '../../services/users';

const ApiKeyManager = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [newApiKeyLoading, setNewApiKeyLoading] = useState(false);

  useEffect(() => {
    fetchApiKeys();
  }, []);

  const fetchApiKeys = async () => {
    setLoading(true);
    try {
      const keys = await listUserApiKeys();
      setApiKeys(keys);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

const handleCreateApiKey = async () => {
  setNewApiKeyLoading(true);
  const tempId = `temp-${Date.now()}`;

  // Add a temporary row
  setApiKeys((prevKeys) => [
    ...prevKeys,
    { api_key: tempId, created_at: Date.now(), temp: true },
  ]);

  try {
    const apiKey = await createUserApiKey();

    setApiKeys((prevKeys) =>
      prevKeys.map((key) => {
        if (key.api_key === tempId) {
          return { api_key: apiKey, created_at: key.created_at };
        }
        return key;
      })
    );
  } catch (err) {
    setError(err.message);

    setApiKeys((prevKeys) =>
      prevKeys.filter((key) => key.api_key !== tempId)
    );
  } finally {
    setNewApiKeyLoading(false);
  }
};

  const handleDeleteApiKey = async (apiKey) => {
    try {
      await deleteUserApiKey(apiKey);
      fetchApiKeys();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleCopyApiKey = (apiKey) => {
    navigator.clipboard.writeText(apiKey).then(() => {
      alert('API key copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy API key:', err);
    });
  };

  return (
    <>
      <Header as="h2">API Keys</Header>

      {error && <Message negative>{error}</Message>}

      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>API Key</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Copy</Table.HeaderCell>
            <Table.HeaderCell>Delete</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {apiKeys.map((key) => (
            <Table.Row key={key.api_key}>
              <Table.Cell>
                {key.temp ? (
                  <Loader active inline size="small" />
                ) : (
                  key.api_key
                )}
              </Table.Cell>
              <Table.Cell>
                { key.temp
                  ? "Loading..."
                  : new Date(key.created_at * 1000).toLocaleString()}
              </Table.Cell>
              <Table.Cell>
                {!key.temp && (
                  <Icon name="copy" link onClick={() => handleCopyApiKey(key.api_key)} />
                )}
              </Table.Cell>
              <Table.Cell>
                {!key.temp && (
                  <Icon name="trash" link onClick={() => handleDeleteApiKey(key.api_key)} />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="4">
              <Icon name="plus" link onClick={handleCreateApiKey} disabled={newApiKeyLoading} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
};

export default ApiKeyManager;
