import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Container, Grid } from 'semantic-ui-react';

import './config/amplifyConfig';
import { Authenticator } from '@aws-amplify/ui-react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { DEFAULT_CONFIG } from './config';

import Collections from './components/Collections';
import CreateCollection from './components/CreateCollection';
import CollectionDetails from './components/CollectionDetails';
import Sidebar from './components/Sidebar';
import LandingPage from './LandingPage';
import UserAccount from './components/users/Account';
import { Models, ModelOverview } from './components/models';
import ApiDocs from './components/ApiDocs';


  const formFields = {
    signUp: {
      email: {
        label: 'Email Address', // Custom label
        placeholder: 'Enter your email address', // Custom placeholder
        isRequired: true, // Make the email field required
      },
      username: {
        label: 'Username',
        placeholder: 'Enter your username',
        isRequired: true, // Username is also required
      },
      password: {
        label: 'Password',
        placeholder: 'Enter your password',
        isRequired: true,
      },
    },
  };

const ProtectedRoute = ({ children }) => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  if (authStatus === 'configuring') {
    return <div>Loading...</div>; // Render a loader while Authenticator is setting up
  }

  return authStatus === 'authenticated' ? children : <Navigate to="/user/login" replace />;
};

const App = () => {
  const [config, setConfig] = useState(DEFAULT_CONFIG);
  //const [selectedCollectionId, setSelectedCollectionId] = useState(null);
  //const [activeItem, setActiveItem] = useState('')

  const { user, authStatus, signOut } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ]);

  return (
    <Router>
      <Grid centered>
        <Grid.Row>
          <Grid.Column width={4}>
            <Sidebar
              currentUser={authStatus === 'authenticated' ? user : null}
              //activeItem={activeItem}
              //setActiveItem={setActiveItem}
              //setSelectedCollectionId={setSelectedCollectionId}
              config={config}
            />
          </Grid.Column>

          <Grid.Column floated="left" width={11}>
            <Container>
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<LandingPage />} />
                <Route path="/user/signup" element={<Authenticator formFields={formFields} initialState="signUp" />} />
                <Route path="/user/login" element={<Authenticator formFields={formFields}/>} />
                <Route path="/collections" element={<Collections />} />
                <Route path="/models" element={<Models />} />

                {/* Protected Routes */}
                <Route path="/user/account" element={<ProtectedRoute><UserAccount handleLogout={signOut} /></ProtectedRoute> } />
                <Route path="/collections/create" element={<ProtectedRoute><CreateCollection /></ProtectedRoute> } />
                <Route path="/collections/:collectionId" element={<ProtectedRoute><CollectionDetails /></ProtectedRoute> } />
                <Route path="/models/:modelType/:modelName" element={<ProtectedRoute><ModelOverview models={config.models} /></ProtectedRoute> } />

                {/* API Docs */}
                <Route path="/docs/database" element={<ApiDocs specUrl="https://web.dev.db.bayis.co.uk/openapi.json" />} />
                <Route path="/docs/models" element={<ApiDocs specUrl="https://web.dev.db.bayis.co.uk/openapi.json" />} />

              </Routes>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Router>
  );
};


export default function AppWrapper() {
  return (
    <Authenticator.Provider>
      <App />
    </Authenticator.Provider>
  );
}
