import React from 'react';


const Models = () => {
  return (
    <>
      <h2>Your Models</h2>
      <h2>Public Models</h2>
    </>
  );
};

export default Models;
