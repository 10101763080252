import React from 'react';


const Collections = () => {
  return (
    <>
      <h2>Your Collections</h2>
      <h2>Public Collections</h2>
    </>
  );
};

export default Collections;
