// FIXME: this should be in api.js
import { fetchAuthSession } from 'aws-amplify/auth';

export const getAuthHeader = async () => {
  try {
    const session = await fetchAuthSession(); // Attempt to fetch the session

    // Check if session or tokens are missing
    if (!session || !session.tokens || !session.tokens.accessToken) {
      throw new Error("Login required: No active session found."); // Explicit error for clarity
    }

    const accessToken = session.tokens.accessToken.toString(); // Extract the token

    //return `Bearer ${accessToken}`; // NB: the api does not accept the Bearer prefix
    return accessToken; // Return the Bearer token
  } catch (error) {
    if (error.message === "Login required: No active session found.") {
      // Handle unauthenticated state gracefully
      console.warn("User is not logged in. No auth header available.");
      return null; // Return null for unauthenticated users
    }

    // Log unexpected errors and rethrow
    console.error("Error fetching authentication session:", error);
    throw error;
  }
};
