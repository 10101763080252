import React from 'react';
import { RedocStandalone } from 'redoc';

const ApiDocs = ({ specUrl }) => {
  return (
    <RedocStandalone
      specUrl={specUrl}
      options={{
        scrollYOffset: 50,
        hideDownloadButton: true,
        theme: { colors: { primary: { main: '#1e70bf' } } },
        resolve: {
          base: "https://web.dev.db.bayis.co.uk", // Set the base URL for resolving $refs
        },
      }}
    />
  );
};

export default ApiDocs;
