import { apiUserRequest } from './api';

/**
 * Create a new API key for the user.
 */
export async function createUserApiKey() {
  try {
    const response = await apiUserRequest("/apikey", {
      method: "POST",
      requireAuth: true,
    });
    return response.api_key;
  } catch (error) {
    throw new Error(`Error creating API key: ${error.message}`);
  }
}

/**
 * Get statistics or details about a specific API key.
 *
 * @param {string} apiKey - The API key to get details for.
 */
export async function getUserApiKeyStats(apiKey) {
  try {
    const response = await apiUserRequest("/apikey", {
      method: "GET",
      requireAuth: true,
      body: { APIKey: apiKey },
    });
    return response.user_data;
  } catch (error) {
    throw new Error(`Error fetching API key stats: ${error.message}`);
  }
}

/**
 * Delete a specific API key for the user.
 *
 * @param {string} apiKey - The API key to delete.
 */
export async function deleteUserApiKey(apiKey) {
  try {
    const response = await apiUserRequest("/apikey", {
      method: "DELETE",
      requireAuth: true,
      body: { APIKey: apiKey },
    });
    return response;
  } catch (error) {
    throw new Error(`Error deleting API key: ${error.message}`);
  }
}

/**
 * List all API keys associated with the user.
 */
export async function listUserApiKeys() {
  try {
    const response = await apiUserRequest('', {
      method: "GET",
      requireAuth: true,
    });
    console.log("list.user.apikeys: ", response);
    return response.api_keys; // Adjust based on the actual response structure
  } catch (error) {
    throw new Error(`Error listing user API keys: ${error.message}`);
  }
}
