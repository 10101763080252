import React, { useState } from 'react';
import {
  Container,
  Header,
  Step,
  Icon,
  Segment,
  Button,
  Grid,
} from 'semantic-ui-react';

const MainPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [runTour, setRunTour] = useState(false);

  // Handler for accordion click
  const handleClick = (index) => {
    const newIndex = activeIndex === index ? null : index;
    setActiveIndex(newIndex);
  };

  // Data for workflow steps
  const steps = [
    {
      title: 'Upload',
      icon: 'upload',
      content: {
        header: 'Easily Upload Your Data',
        description:
          'Quickly import your documents, images, and other data types into Marigold. Our user-friendly interface is designed for everyone, ensuring that managing your data is quick and hassle-free.',
        examples: [
          'Upload product images for your e-commerce store.',
          'Import textual data such as customer reviews or product descriptions.',
          'Manage digital assets like brochures and marketing materials.',
        ],
      },
    },
    {
      title: 'Index',
      icon: 'database',
      content: {
        header: 'Advanced AI based Indexing',
        description:
          'Leverage the latest AI models to index your data effectively. Marigold organizes your information, making it accessible for future searches and interactions.',
        examples: [
          'Automatically categorize and tag your inventory items.',
          'Enhance searchability of digital documents.',
          'Optimize data retrieval for customer support inquiries.',
        ],
      },
    },
    {
      title: 'Search',
      icon: 'search',
      content: {
        header: 'Powerful Searching and Matching',
        description:
          'Condunct insightful searches on your data. Whether you’re matching inputs to outputs or finding existing data, Marigold delivers accurate and relevant results in seconds.',
        examples: [
          'Discover products similar to a top-selling item in your inventory.',
          'Retrieve documents related to a specific project or client.',
          'Match customer inquiries with the most relevant support articles or solutions.',
        ],
      },
    },
    {
      title: 'Instruct',
      icon: 'chat',
      content: {
        header: 'Instruct AI to complete your tasks',
        description:
          'Indentify the automations which solve business tasks with data. Summarise, transform, send, analyze, and act on the informatin your business captures. Marigold will build these steps with you, and help you test solutions.',
        examples: [
          'Ask for a summary of customer feedback on a product and develop a todo list.',
          'Discover inventory levels across different locations and schedule purchasing.',
          'Request insights on sales trends over the past quarter and develop strategies.',
        ],
      },
    },
    {
      title: 'Automate',
      icon: 'cogs',
      content: {
        header: 'Automate your Solutions',
        description:
          'Transform valuable insights into automated workflows that are accessible anytime, anywhere. Marigold enables you to streamline repetitive tasks, saving time and reducing manual effort across your business operations.',
        examples: [
          'Extract invoice information and create a payment.',
          'Identify customer concerns and habits to generate actionable to-do lists for your team.',
          'Summarize monthly performance to keep your business stratgies on track.',
        ],
      },
    },
  ];

  // Data for testimonials
  const testimonials = [
    {
      quote:
        '**Marigold** has revolutionized how we manage and interact with our data. The ability to search and converse with our inventory has significantly boosted our efficiency and customer satisfaction!',
      author: 'Jane Doe, Owner of Trendy Boutique',
    },
    {
      quote:
        'The intuitive interface and powerful search capabilities make **Marigold** an indispensable tool for our e-commerce business. It’s like having a smart assistant for our data.',
      author: 'John Smith, Manager at TechGear Store',
    },
  ];

  return (
    <Container style={{ marginTop: '2em', marginBottom: '2em' }}>
      {/* Header Section */}
      <Header as='h1'>
        <Header.Content>
          Marigold
          <Header.Subheader>Intelligent Data Solutions for Small Businesses</Header.Subheader>
        </Header.Content>
      </Header>

      {/* Overview */}
      <Segment>
        Do you want greater insight of your data? Are you looking for a way to automate the simple stuff? Marigold can help you unlock your business by putting the latest AI technology into your hands!
      </Segment>

      {/* Grid Layout for Steps and Content */}
      <Grid>
        <Grid.Row>
          {/* Left Column: Vertical Steps */}
          <Grid.Column width={6}>
            <Step.Group ordered vertical fluid>
              {steps.map((step, index) => (
                <Step
                  key={index}
                  onClick={() => handleClick(index)}
                  active={activeIndex === index}
                  link
                  className={`step-${index}`} // Unique class for targeting in the Joyride
                >
                  <Icon name={step.icon} />
                  <Step.Content>
                    <Step.Title>{step.title}</Step.Title>
                    <Step.Description>{step.description}</Step.Description>
                  </Step.Content>
                </Step>
              ))}
            </Step.Group>
          </Grid.Column>

          {/* Right Column: Expanded Content */}
          <Grid.Column width={10}>
            {activeIndex !== null && (
              <Segment>
                <Header as='h3'>{steps[activeIndex].content.header}</Header>
                <p>{steps[activeIndex].content.description}</p>
                <Header as='h4'>Examples:</Header>
                <ul>
                  {steps[activeIndex].content.examples.map((example, exIndex) => (
                    <li key={exIndex}>{example}</li>
                  ))}
                </ul>
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {/* Call to Action */}
      <Segment textAlign='center' vertical>
        <Button primary size='huge' href='/user/signup'>
          <Header as='h2'>Sign up</Header>
        </Button>
      </Segment>
    </Container>
  );
};

export default MainPage;
