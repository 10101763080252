import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Comment, Form, Button, Segment, Message, TextArea } from 'semantic-ui-react';
import { instruct, pollForResult } from '../../services/instruct';
import ChatMessage from './ChatMessage';
//import SystemMessage from './SystemMessage';
import { v4 as uuidv4 } from 'uuid'; // For unique IDs

import { useAuthenticator } from '@aws-amplify/ui-react';

//import { getCurrentUser } from '../../services/auth';

const systemMessage = {
  id: uuidv4(),
  role: "system",
  username: "system",
  content: "you're a buddy old pal, old friend! Tell me good news.",
  created: new Date().toISOString(),
}

const InstructInteraction = () => {
  const { modelType, modelName } = useParams(); // Extract type and name from the URL params
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([systemMessage]); // Initialize as empty; system message will be added on mount
  const [loading, setLoading] = useState(false); // Loading state for the submit button
  const [error, setError] = useState(null); // Global error state
  const [isEditingSystem, setIsEditingSystem] = useState(false); // State for editing system prompt
  const [editedSystemContent, setEditedSystemContent] = useState(''); // State for edited system prompt

  const { user, authStatus } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ]);

  // Function to get all messages
  const getAllMessages = () => {
    return messages;
  };

  // Function to get the last five messages, ensuring the system prompt is included
  const getLastFiveMessages = () => {
    // Always include the system prompt (assumed to be the first message)
    const systemPrompt = messages.find(msg => msg.role === 'system');
    const lastFive = messages.slice(-5);
    return systemPrompt ? [systemPrompt, ...lastFive] : lastFive;
  };

  // Function to get context messages for replying to a specific message
  const getContextMessagesForReply = (messageId) => {
    const context = [];
    const localMap = new Map(messages.map(msg => [msg.id, msg]));

    let currentId = messageId;

    while (currentId) {
      const currentMessage = localMap.get(currentId);
      if (currentMessage) {
        context.unshift(currentMessage); // Add to the beginning
        currentId = currentMessage.parentId;
      } else {
        break; // If message not found, stop traversal
      }
    }

    // Ensure the system prompt is included
    const systemPrompt = messages.find(msg => msg.role === 'system');
    if (systemPrompt && !context.includes(systemPrompt)) {
      context.unshift(systemPrompt);
    }

    return context;
  };

  // send a message with context to the backend
  const sendMessageToBackend = async (latestMessages, modelMessage) => {
    try {
      // Send context messages to the backend and get messageId
      const messageId = await instruct(latestMessages, modelName);
      console.log('Received messageId: ', messageId);

      // Poll for the response
      const result = await pollForResult(messageId);
      console.log('Received result: ', result);

      // Update the model message with the actual response
      setMessages((prev) =>
        prev.map((msg) =>
          msg.id === modelMessage.id
            ? {
                ...msg,
                content: result.choices?.[0]?.content || 'empty response',
                loading: false,
                usage: result.usage,
                status: result.status,
                created: new Date(parseInt(result.created) * 1000).toISOString(),
              }
            : msg
        )
      );
    } catch (err) {
      console.error('Error in sendMessageToBackend:', err);
      // Update the model message with the error
      setMessages((prev) =>
        prev.map((msg) =>
          msg.id === modelMessage.id
            ? { ...msg, error: err.message, loading: false, status: 'error' }
            : msg
        )
      );
      setError('Failed to send instruction. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Edit the system prompt
  const handleEditSystem = () => {
    setIsEditingSystem(true);
  };

  const handleSaveSystem = () => {
    setMessages((prev) =>
      prev.map((msg) =>
        msg.role === 'system'
          ? { ...msg, content: editedSystemContent, created: new Date().toISOString() }
          : msg
      )
    );
    setIsEditingSystem(false);
  };

  const handleCancelEditSystem = () => {
    const systemMsg = messages.find(msg => msg.role === 'system');
    if (systemMsg) {
      setEditedSystemContent(systemMsg.content);
    }
    setIsEditingSystem(false);
  };

  const handleResend = async (failedMessage) => {
    setLoading(true);
    setError(null);
    console.log("resending failed message...");
  }

  // delete a message from the list
  const handleDelete = (messageToDelete) => {
    setMessages((prev) => prev.filter((msg) => msg.id !== messageToDelete.id));
  };

  const handleClear = () => {
    // clear the messages on the client
    // NB: we don't have a button for this at the moment
    setMessages([systemMessage]);
  };

  // Send Message Handler
  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (!message.trim()) return; // Prevent sending empty messages

    setLoading(true);
    setError(null);

    //const user = "username";
    //console.log("user: ", user);

    const userMessage = {
      id: uuidv4(),
      role: "user",
      content: message.trim(),
      created: new Date().toISOString(),
      username: user.username
    };

    const modelMessage = {
      id: uuidv4(),
      role: "assistant",
      content: '', // To be filled after response
      username: modelName,
      loading: true,
      error: null,
      status: 'started', // 'started', 'pending', 'complete'
      created: new Date().toISOString(),
      usage: null,
    };

    try {
      // Prepare context messages based on whether it's a reply
      const contextMessages = getLastFiveMessages();
      const latestMessages = [...contextMessages, userMessage];

      console.log("Sending contextMessages...", latestMessages);

      // Add the user message to the state
      setMessages((prev) => [...prev, userMessage]);

      // Send context messages to the backend and get messageId
      const messageId = await instruct(latestMessages, modelName);
      console.log("Received messageId: ", messageId);

      // Add the placeholder model message
      setMessages((prev) => [...prev, modelMessage]);

      // Send message to backend
      await sendMessageToBackend(latestMessages, modelMessage);

      setMessage('');
    } catch (err) {
      console.error("Error in handleSendMessage:", err);
      // Update the model message with the error
      setMessages((prev) =>
        prev.map((msg) =>
          msg.id === modelMessage.id
            ? { ...msg, error: err.message, loading: false }
            : msg
        )
      );
      setError('Failed to send instruction. Please try again.');
    } finally {
      setLoading(false);
      setMessage('');
    }
  };

  // Auto-scroll to the latest message
  useEffect(() => {
    const container = document.getElementById('chat-container');
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [messages]);

  return (
    <Segment id="chat-container" style={{ maxHeight: '500px', overflowY: 'auto', marginBottom: '1em' }}>
      <Comment.Group>
        {
          // Linear Rendering
          messages.map((msg) => {
            if (msg.role === 'system') {
              return (
                <ChatMessage
                  key={msg.id}
                  message={msg}
                  isEditing={isEditingSystem}
                  editedContent={editedSystemContent}
                  onEdit={handleEditSystem}
                  onSave={handleSaveSystem}
                  onCancel={handleCancelEditSystem}
                  onChange={setEditedSystemContent}
                />
              );
            } else {
              return (
                <ChatMessage
                  key={msg.id}
                  message={msg}
                  onResend={handleResend}
                  onDelete={handleDelete}
                />
              );
            }
          })
        }
      </Comment.Group>

      <Form onSubmit={handleSendMessage}>
        <Form.Field>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextArea
              placeholder={"Enter your message..."}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              style={{ flex: 1, marginRight: '0.5em' }}
              rows={2}
            />
            <Button type="submit" loading={loading} disabled={!message.trim()} primary>
              Send
            </Button>
          </div>
        </Form.Field>
        {error && <Message error content={error} />}
      </Form>
    </Segment>
  );
};

export default InstructInteraction;
