import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon, Image } from 'semantic-ui-react';
import AuthenticatedCollections from './CollectionList';
import ModelList from './ModelList';

import { squareLogo } from '../assets/assets_cdn';

const Sidebar = ({ currentUser, /* activeItem, setActiveItem, setSelectedCollectionId, */ config }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // Helper function to check authentication
  const isAuthenticated = () => !!currentUser;

  return (
    <Menu vertical>
      <Menu.Item as={Link} to="/" name="Home" >
        <Image src={squareLogo} size="small" />
      </Menu.Item>

      <Menu.Item as={Link} to={ "/user/account" } name={isAuthenticated() ? currentUser.username : "account"}>
        {isAuthenticated() ? currentUser.username : "Account"}
      </Menu.Item>

    <Menu.Item>
      <Menu.Header as={Link} to="/collections/">Collections</Menu.Header>
      <Menu.Menu>
        { isAuthenticated() && <AuthenticatedCollections config={config} /> }
      </Menu.Menu>
    </Menu.Item>


      <Menu.Item>
        <Menu.Header as={Link} to="/models">Models</Menu.Header>
        <Menu.Menu>
          <ModelList activeIndex={activeIndex} handleAccordionClick={handleAccordionClick} config={config} />
        </Menu.Menu>
      </Menu.Item>

      <Menu.Item>
        <Menu.Header>API</Menu.Header>
        <Menu.Menu>
          <Menu.Item as={Link}  to="/docs/database">Database</Menu.Item>
          <Menu.Item as={Link}  to="/docs/models">Models</Menu.Item>
        </Menu.Menu>
      </Menu.Item>

      <Menu.Item>
        <Menu.Header>Contact</Menu.Header>
        <Menu.Menu>
          <Menu.Item as="a" href="mailto:vec@bayis.co.uk"><Icon name="envelope" />vec@bayis.co.uk</Menu.Item>
          { /*
            <Menu.Item as="a" href="https://www.facebook.com/pages/PopStory/100094354340535/" target="_blank" rel="noopener noreferrer">
              <i className="facebook icon"></i> PopStory
            </Menu.Item>
            <Menu.Item as="a" href="https://www.instagram.com/popstorysupport/" target="_blank" rel="noopener noreferrer">
              <i className="instagram icon"></i> @popstorySupport
            </Menu.Item>
          */}
          <Menu.Item>
          <p>&copy; 2024</p>
          <p><a href="http://www.bayis.co.uk/" target="_blank" rel="noopener noreferrer">Bay Information Systems</a></p>
          <p>All Rights Reserved.</p>
          </Menu.Item>
        </Menu.Menu>
      </Menu.Item>

    </Menu>
  );
};

export default Sidebar;
