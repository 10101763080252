import React from 'react';
import { Message } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

import ModelCard from './ModelCard';

const ModelOverview = ({ models }) => {
  const { modelType, modelName } = useParams(); // Extract type and name from the URL params
  const model = models[modelType]?.[modelName]; // Get the model details based on type and name

  // If modelName is not provided, render all models of the specified type
  if (!modelName) {
    const modelsOfType = Object.keys(models[modelType] || {});

    if (modelsOfType.length === 0) {
      return (
        <Message error>
          <Message.Header>No Models Found</Message.Header>
          <p>No models of type "{modelType}" could be found.</p>
        </Message>
      );
    }

    return (
      <div>
        {modelsOfType.map((name) => {
          const currentModel = models[modelType][name];
          return <ModelCard key={name} model={currentModel} modelName={name} />;
        })}
      </div>
    );
  }

  if (!model) {
    return (
      <Message error>
        <Message.Header>Model Not Found</Message.Header>
        <p>The requested model could not be found.</p>
      </Message>
    );
  }

  return <ModelCard model={model} modelName={modelName} />;
};

export default ModelOverview;
