import React, { useEffect, useState } from 'react';
import { Table, Message, Header, Loader, Button, Dropdown, Icon, Menu } from 'semantic-ui-react';
import { apiUsageRequest } from '../../services/api';

const UsageStats = ({ apiRootName }) => {
  const [rows, setRows] = useState([]);
  const [timeRange, setTimeRange] = useState('day'); // 'day' or 'month'
  const [loading, setLoading] = useState(true);
  const [maxColumns, setMaxColumns] = useState(1); // Track the maximum number of columns

  const getLastSixMonths = () => {
    const dates = [];
    const today = new Date();
    for (let i = 0; i < 6; i++) {
      const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      dates.push({ year, month });
    }
    return dates;
  };

  const getLastTenDays = () => {
    const dates = [];
    const today = new Date();
    for (let i = 0; i < 10; i++) {
      const date = new Date();
      date.setDate(today.getDate() - i);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      dates.push({ year, month, day });
    }
    return dates;
  };

  const fetchRowData_old = async (date, rowIndex) => {
    const { year, month, day } = date;
    const endpoint = timeRange === 'day'
      ? `/day/${year}${month}${day}`
      : `/month/${year}${month}`;

    try {
      const response = await apiUsageRequest(apiRootName, endpoint, { method: 'GET' });

      if (response && response.length > 0) {
        const dataKeys = Object.keys(response[0]);
        setMaxColumns((prevMax) => Math.max(prevMax, dataKeys.length+1)); // Update maxColumns if needed
      }

      const formattedData = response ? response.map((entry) => ({
        ...entry,
        date: timeRange === 'day' ? `${year}-${month}-${day}` : `${year}-${month}`,
      })) : [];

      setRows((prevRows) => {
        const updatedRows = [...prevRows];
        updatedRows[rowIndex] = { status: 'success', data: formattedData };
        return updatedRows;
      });
    } catch (err) {
      setRows((prevRows) => {
        const updatedRows = [...prevRows];
        updatedRows[rowIndex] = { status: 'error', error: err.message };
        return updatedRows;
      });
    }
  };


const fetchRowData = async (date, rowIndex) => {
  const { year, month, day } = date;
  const endpoint = timeRange === 'day'
    ? `/day/${year}${month}${day}`
    : `/month/${year}${month}`;

  try {
    const response = await apiUsageRequest(apiRootName, endpoint, { method: 'GET' });

    if (response && response.length > 0) {
      const dataKeys = Object.keys(response[0]);
      setMaxColumns((prevMax) => Math.max(prevMax, dataKeys.length + 2)); // Include 'cost' in columns
    }

const formattedData = response
  ? response.map((entry) => {
      const memoryUsage = entry.memory_usage || 4000; // Default memory usage to 4000 MB if not provided
      const memoryInGB = memoryUsage / 1024; // Convert memory usage to GB

      const cost =
        entry.duration !== undefined
          ? (entry.duration / 1000) * memoryInGB * 0.0000166667 // Convert duration to seconds
          : Object.entries(entry)
              .filter(([key]) => key.endsWith('_time'))
              .reduce((sum, [, value]) => sum + value / 1000, 0) * memoryInGB * 0.0000166667; // Sum *_time fields and convert to seconds

      return {
        ...entry,
        date: timeRange === 'day' ? `${year}-${month}-${day}` : `${year}-${month}`,
        cost: parseFloat(cost.toFixed(10)), // Store with high precision
      };
    })
  : [];

    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex] = { status: 'success', data: formattedData };
      return updatedRows;
    });
  } catch (err) {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex] = { status: 'error', error: err.message };
      return updatedRows;
    });
  }
};

  const fetchUsageStats = () => {
    setLoading(true);
    const dates = timeRange === 'day' ? getLastTenDays() : getLastSixMonths();

    // Initialize rows and reset maxColumns
    setRows(dates.map(() => ({ status: 'loading', data: null, error: null })));
    setMaxColumns(1);

    dates.forEach((date, index) => {
      fetchRowData(date, index);
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchUsageStats();
  }, [timeRange, apiRootName]);

  const handleTimeRangeChange = (e, { value }) => {
    setTimeRange(value);
  };

  const timeRangeOptions = [
    { key: 'day', text: 'Last 10 Days', value: 'day' },
    { key: 'month', text: 'Last 6 Months', value: 'month' },
  ];

  return (
    <div>
      <Table celled striped>
        {/* Dynamic Table Headers */}
        {maxColumns > 0 && (
          <Table.Header>
            <Table.Row>
              {rows.some((row) => row.data && row.data.length > 0) &&
                Object.keys(rows.find((row) => row.data && row.data.length > 0)?.data[0] || {}).map(
                  (key) => (
                    <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
                  )
                )}
            </Table.Row>
          </Table.Header>
        )}
        <Table.Body>
          {rows.map((row, index) => (
            <Table.Row key={index}>
              {/* If there's an error, show it across all columns */}
              {row.status === 'error' ? (
                <Table.Cell colSpan={maxColumns}>
                  <Message negative>{row.error}</Message>
                </Table.Cell>
              ) : row.data && row.data.length > 0 ? (
                row.data.map((entry, idx) => (
                  Object.values(entry).map((value, valueIdx) => (
                    <Table.Cell key={valueIdx}>
                      {typeof value === 'number' ? value.toFixed(7) : value}
                    </Table.Cell>
                  ))
                ))
              ) : (
                <Table.Cell colSpan={maxColumns}>
                  {row.status === 'loading' && <Loader active inline size="small" />}
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>
              <Icon name="refresh" link onClick={fetchUsageStats} title="Refresh" />
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={maxColumns-1}>
              <Dropdown placeholder="Range" selection options={timeRangeOptions} onChange={handleTimeRangeChange} value={timeRange} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
};

export default UsageStats;
